@color_1: #455860;
@color_2: #cc1b30;
@color_3: #8fa5af;
@color_4: #f0f6f9;
@color_5: #337ab7;
@color_6: #dee6e6;
@color_7: #000000;
@color_8: #ffffff;

html.cover-page {
  background-color: none;
}

.cover-page {
  background-color: @color_4;
  background-image: none;
  border-color: transparent;
  color: #fff;
}

a.ui.inverted.header.tiny {
  color: rgba(0,0,0,.87);
}

div.invenio-header-container.mc_header {
	width: auto !important;
	margin: 0px !important;
	background-color: white;
}

div.item.logo.p-0.mc_logo_header {
	width: auto;
	height: 62px;
	padding-left: 20px !important;
	padding-right: 17px !important;
	padding-top: 2px !important;
}

img.ui.image.rdm-logo.mc_logo_image_header {
	width: 62px;
	height: 50px;
}

.mc_item_header {
	color: @color_1 !important;
	font-size: 16px !important;
	font-family: 'Open Sans',sans-serif !important;
	font-weight: 600 !important;
	line-height: 32px !important;
	padding-top: 28px;
}

@media only screen and (max-width: 1298px) {
	.mc_item_header {
		padding-top: 0px !important;
	}
}
div.item a.mc_item_header.active {
	color: @color_2 !important;
}

div.item a.mc_item_header:hover:not(.active) {
	color: @color_3 !important;
}

div.ui.dropdown.icon.button.floating.mc_item_header {
	background: none !important;
}

.infobar {
	color: @color_5 !important;
	font-family: Helvetica Neue;
	font-size: 14px;
}

.accessbar {
	color: @color_1 !important;
	font-family: Helvetica Neue;
	font-size: 14px;
}

.mc_header_invenio_menu {
	width: 100%;
	height: 62px;
	padding-left:20px;
	padding-right:20px;
	background-color: @color_4 !important;
	border-top: 3px solid @color_6 !important;
}

.mc_outer_navbar {
	padding-bottom: 46px;
	border-bottom: 0px !important;
	background-color: white;
	background-image: None;
}

@media only screen and (max-width: 1298px) {
	.mc_outer_navbar {
		padding-bottom: 0px;
	}
}

.mc_header_invenio_right_menu {
	width: 540px;
}

div#invenio-menu.ui.fluid.menu.borderless.mobile-hidden.grid div.row {
	height: 62px;
	padding-top: 0px !important;
	padding-bottom: 0px !important
}

@media only screen and (max-width: 1298px) {
	div#invenio-menu.ui.fluid.menu.borderless.mobile-hidden.grid div.row {
		height: auto;
	}
}

.mc_border_bottom {
	border-bottom: 3px solid @color_6 !important;
}

.mc_border_top {
	border-top: 3px solid @color_6 !important;
}

@media only screen and (max-width: 1298px) {
	div.ui.container.invenio-header-container.mc_header {
		border-bottom: 3px solid @color_6 !important;
	}
}

.mc_modal {
	background-color: @color_8 !important;
	color: @color_7 !important;
}

.color_black {
	color: @color_7 !important;
}

button.button_as_link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: @color_5;
    text-decoration: none;
    cursor: pointer;
}

// upload form: accordion
.ui.inverted.accordion .title:not(.ui) {
	background-color: @color_5 !important;
}

// record: interfaces
ul.interfaces {
  padding-left: 0em;
  list-style: none;
}
ul.interfaces li {
  padding-left: 1.5em;
  min-height: 3.5em;
}
ul.interfaces li.explore {
    background: url('../../../../static/images/aiida-logo-138x128.png') no-repeat left 0.25em;
    background-size: 3em;
}
ul.interfaces li.discover {
    background: url('../../../../static/images/mcloud-logo-98x79.png') no-repeat left 0.25em;
    background-size: 3em;
}
ul.interfaces li.explore a {
	line-height: 3em;
}
ul.interfaces li.discover a {
	line-height: 3em;
}

div#other_apps_popup.ui.button {
	background: none;
	padding: 0px;
}

a[id="other_apps_link"] {
    text-decoration: none;
	display: inline-block;
}

a[id="other_apps_link"]:focus-visible {
  outline: 0px !important;
}

.display_block {
	display:block !important;
}

.fz-14 {
	font-size: 14px;
}

.hidden {
	display: none;
}

.line_height-15 { 
	line-height: 15px !important;
}

.mc_header_family {
	font-family: 'Open Sans',sans-serif !important;
}

.opacity-1 {
	opacity: 1 !important;
}

.text_justify {
	text-align: justify !important;
}

.h-0 {
	height: 0px !important;
}

.h-62 {
	height: 62px !important;
}

pb-5 {
	padding-bottom: 5px !important;
}

pb-10 {
	padding-bottom: 10px !important;
}

pl-5 {
	padding-left: 5px !important;
}

pl-10 {
	padding-left: 10px !important;
}

pr-2 {
	padding-right: 2px !important;
}

pr-5 {
	padding-right: 5px !important;
}

pr-20 {
	padding-right: 20px !important;
}

pt-10 {
	padding-top: 10px !important;
}
